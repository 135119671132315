// import Vue from 'vue'
// import index from './Message.vue'
// const vms = {
//   create(Component, obj) {
//     let vm = new Component()
//     vm = vm.$mount()
//     if (obj) {
//       for (const o in obj) {
//         vm[o] = obj[o]
//       }
//       const wrap = document.getElementById('global-msg-wrap')
//       wrap.innerHTML = ''
//       wrap.appendChild(vm.$el)
//       return vm
//     }
//   }
// }
// // const MessageConstructor = Vue.extend(index)

// let instance
// let instances = []
// let seed = 1

// const Message = (args = {}) => {
//   const vm = vms.create(MessageConstructor, args)
//   Object.assign(vm, args)
//   instances.push(vm)
// }
// // 成功
// Message.success = (args) => {
//   const list = {}
//   list.type = 'success'
//   list.msg = args
//   Message(list)
// }

// // 警告
// Message.warn = (args) => {
//   for (let i = instances.length - 1; i >= 0; i--) {
//     instances[i].close()
//   }
//   const list = {}
//   list.type = 'warn'
//   list.msg = args
//   Message(list)
// }

// // 错误
// Message.error = (args) => {
//   const list = {}
//   list.type = 'error'
//   list.msg = args
//   Message(list)
// }

// export default Message
import store from '@/store'
let instances = []
const Message = (args = {}) => {
  args.isShow = true
  store.commit('user/globalMessageData', args)
}
// 成功
Message.success = (args) => {
  const list = {}
  list.type = 'success'
  list.msg = args
  list.code = 200
  Message(list)
}

// 警告
Message.warn = (args) => {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].close()
  }
  const list = {}
  list.type = 'warn'
  list.msg = args
  Message(list)
}

// 错误
Message.error = (args) => {
  const list = {}
  list.type = 'error'
  list.msg = args
  Message(list)
}
export default Message