import Vuex from 'vuex'
// 状态保持，防止数据刷新丢失
import createPersistedState from 'vuex-persistedstate'
// 项目足够大时，将store进行拆分成若干moudle
// 存放公司信息
import company from './modules/company'
import user from './modules/user'
export default new Vuex.Store({
  modules: {
    namespaced: true,
    company,
    user
  },
  plugins: [createPersistedState({
    reducer: state => {
      return {
        namespaced: state.namespaced,
        company: state.company,
        user: state.user
      };
    }
  })]
})