// 解决elementui el-tooltip，在弹框之后再次出现的问题
export default {
  mounted(el, binding) {
    // el为绑定的元素，binding为绑定给指令的对象
    el.__vueSetTimeoutIndex__ = setTimeout(() => {
      // 清除当前tabIndex
      el.removeAttribute('tabindex')
      clearTimeout(el.__vueSetTimeoutIndex__)
    }, 0)
  },
  unmounted(el) {
    clearTimeout(el.__vueSetTimeoutIndex__)
  }
}