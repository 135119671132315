<template>
  <div class="header-wrap">头部</div>
</template>
<script>
export default {
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>
<style lang="less" scoped>
</style>
