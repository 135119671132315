import timeHandle from './time'
// 公用的函数工具库
const isQw =
  window.location.host.includes('qywx') || window.location.host.includes('qw')

function getQueryString(name, type) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (type === 'seo') {
    if (window.location.href.includes(`${name}`)) {
      var str = decodeURIComponent(window.location.href)
      return str.slice(str.indexOf(`${name}=`)).replace(`${name}=`, '')
    }
  } else {
    if (r != null) return unescape(r[2])
  }
  return null
}

function getQueryStringHash(name, type, query) {
  var locationHash = ''
  if (type === 1) {
    locationHash = query
  } else {
    var w = location.hash.indexOf('?')
    locationHash = location.hash.substring(w + 1)
  }
  const vars = locationHash.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] == name) {
      return pair[1]
    }
  }
  return null
}
// 微信签名url
function ticketAgentUrl() {
  var url = window.location.href
  if (url.indexOf('#') !== -1) {
    return url.split('#')[0]
  } else {
    return url
  }
}
// 页面url
function windowLocationHref() {
  return `https://${window.location.host}/api/admin/v2/user/loginAuthorize`
}

// html转okrt
function changeToOkrtFormat(data) {
  if (!data) {
    return data
  }
  const regLine = /<div class="lineFeed">(.*?)<\/div>? ?/g
  const changeHtmlLine = '\n$1'
  data = data.replace(regLine, changeHtmlLine)
  const reg =
    /<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="(\d+)">(@.*?)<\/span> <\/span>(&nbsp;)? ?/g
  const changeHtml = '[okrt$0|$1|$2|#3068FF$okrt]'
  return isQw ? data : this.tagFilter(data.replace(reg, changeHtml), 'false')
}
// function changeToOkrtFormat(data) {
//   // console.log('object', data)
//   if (!data) {
//     return data
//   }
//   const reg =
//     /<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="(\d+)">(@.*?)<\/span> <\/span> ?/g
//   const changeHtml = '[okrt$0|$1|$2|#3068FF$okrt]'
//   const result = data.replace(reg, changeHtml)
//   // const reg1 =
//   //   /<span data-type="(.*?)" style="background:(.*?) !important;">(.*?)<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="(\d+)">(@.*?)<\/span> <\/span>(.*?)<\/span> ?/g
//   // const changeHtml1 = '[okrt$1||$3[okrt$0|$4|$5|#3068FF$okrt]$2$okrt]'
//   // const result1 = result.replace(reg1, changeHtml1)
//   const reg2 =
//     /<span class="at-text-bg" data-type="(.*?)" style="background:(.*?) !important;">(.*?)<\/span> ?/g
//   const changeHtml2 = '[okrt$$1||$3|$2$okrt]'
//   const result2 = tagFilter(result.replace(reg2, changeHtml2))
//   return result2
// }

// okrt转html
function changeToHtml(str) {
  if (!str) {
    return str
  }
  const reg = /\[okrt\$\d{1,4}\|(\d+)\|(@.*?)\|\#(.{6})\$okrt]/g
  const changeHtml =
    '<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="$1">$2</span> </span>&nbsp;'
  try {
    str = str.replace(/(\n|\r|\r\n|↵)/g, '<br>')
    return str.replace(reg, changeHtml)
  } catch (error) {
    return str
  }
}
// okrt转html用户
function changeToHtmlUser(str, type, list) {
  if (!str) {
    return str
  }
  var childNameList = {} // 存放用户父目标数据是否创建
  var childObjectivesAssociationObjectivesList = list
  var businessId = type.id
  childObjectivesAssociationObjectivesList.forEach((oItem) => {
    var userId = oItem.isAlign === 0 ? oItem.userid + '' : oItem.userDto.id + ''
    var userAvatar = oItem.userDto.avatar
    var userName = oItem.userDto.name
    var jobTitle = oItem.userDto.jobTitle
    var objectivesAssociationList = []
    var departName = oItem.userDto.departName
    var departNameAll = oItem.userDto.departNameAll
    if (isQw && departNameAll) {
      departNameAll = departNameAll.replace('/', ',')
      if (departNameAll.includes(',')) {
        var departNameAllLen = departNameAll.split(',')
        departNameAll = ''
        departNameAllLen.forEach((item, index) => {
          departNameAll += `<ww-open-data type="departmentName" openid="${item}"></ww-open-data> ${
            index < departNameAllLen.length - 1 ? ',' : ''
          }`
        })
      } else if (departNameAll) {
        departNameAll = `<ww-open-data type="departmentName" openid="${departNameAll}"></ww-open-data>`
      }
    }
    if (oItem && oItem.objectivesAssociationList) {
      objectivesAssociationList = oItem.objectivesAssociationList
    }
    if (childNameList[userId]) {
      // 已创建
      if (oItem.isAlign !== 0) {
        objectivesAssociationList.forEach((fatherItem) => {
          if (fatherItem.businessId === businessId) {
            childNameList[userId].list.push(oItem)
          }
        })
      }
    } else {
      if (oItem.isAlign === 0) {
        // 邀请数据
        childNameList[userId] = {
          userId,
          userAvatar,
          userName,
          jobTitle,
          departName,
          departNameAll,
          isAlign: oItem.isAlign,
          list: oItem,
          objectivesAssociationList: []
        }
      } else {
        // 对齐数据
        objectivesAssociationList.forEach((fatherItem) => {
          if (fatherItem.businessId === businessId && !childNameList[userId]) {
            childNameList[userId] = {
              userId,
              userAvatar,
              userName,
              jobTitle,
              departName,
              departNameAll,
              isAlign: oItem.isAlign,
              list: [oItem],
              objectivesAssociationList: objectivesAssociationList
            }
          }
        })
      }
    }
  })
  var strHtmlList = []
  var strCurrent = ''
  const reg =
    /<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="(\d+)"> @ <ww-open-data type="userName" openid="(.*?)"><\/ww-open-data><\/span> <\/span> ?/g
  const changeHtml = '[okrt$0|$1|$2|#3068FF$okrt]'
  const reg2 =
    /<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="(\d+)" data-name="(.*?)"> @ <ww-open-data type="userName" openid="(.*?)"><\/ww-open-data><\/span> <\/span> ?/g
  strCurrent = str.replace(reg, changeHtml)
  strCurrent = strCurrent.replace(reg2, changeHtml)
  // strCurrent = this.tagFilter(strCurrent) // 未匹配人不显示问题  先隐藏去标签
  strCurrent = strCurrent.replace(/[\r\n]/g, '')
  strCurrent = strCurrent.replace(/&amp;/g, '&')
  const regSplit = /\[okrt(.*?)okrt]/g
  var strSplitLen = strCurrent.split(regSplit)
  strSplitLen.forEach((item) => {
    if (item) {
      if (
        item.indexOf('#3068FF') !== -1 ||
        (item.indexOf('$0|') !== -1 && item.indexOf('@') !== -1)
      ) {
        var userId = ''
        var userName = ''
        if (isQw) {
          item = item.replace('$0', '')
          userId = item.split('|')[1]
          userName = item.split('|')[2]
        } else {
          if (item.indexOf('$0|') !== -1) {
            userId = item.split('$0|')[1].split('|')[0]
            userName = item.split('$0|')[1].split('|')[1]
          }
        }
        strHtmlList.push({
          userId,
          userName: childNameList[userId] ? childNameList[userId].userName : '',
          userAvatar: childNameList[userId] ?
            childNameList[userId].userAvatar : '',
          jobTitle: childNameList[userId] ? childNameList[userId].jobTitle : '',
          departName: childNameList[userId] ?
            childNameList[userId].departName : '',
          departNameAll: childNameList[userId] ?
            childNameList[userId].departNameAll : '',
          type: '@',
          isAlign: childNameList[userId] ? childNameList[userId].isAlign : 0, // 没有人员对象，默认未对齐
          list: childNameList[userId] ? childNameList[userId].list : '',
          objectivesAssociationList: childNameList[userId] ?
            childNameList[userId].objectivesAssociationList : [],
          html: `<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name embedded-item-user embedded-item-user-${
            (childNameList[userId] && childNameList[userId].isAlign !== 0) ||
            false
          }" data-id="${userId}">${
            isQw
              ? ` @ <ww-open-data type="userName" openid="${userName}"></ww-open-data>`
              : `${userName}`
          }</span> </span>`
        })
      } else {
        strHtmlList.push({
          type: 'html',
          html: item
        })
      }
    }
  })
  return strHtmlList
  // const reg = /\[okrt\$\d{1,4}\|(\d+)\|(@.*?)\|\#(.{6})\$okrt]/g
  // const changeHtml =
  //   '<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="$1">$2</span> </span>&nbsp;'
  // return str.replace(reg, changeHtml)
}

// 去除html标签
function tagFilter(val, type) {
  if (val) {
    var noHtmlVal = val.replace(/<\/?[^<>]*>|&nbsp;/g, '')
    if ((type || '') === '') {
      noHtmlVal = noHtmlVal.replace(/(^\s*)|(\s*$)|[\r\n]/g, '')
    }
    noHtmlVal = noHtmlVal.replace(/&amp;/g, '&')
    return noHtmlVal
  }
}
// 数组转对象
function arrToObj(arr) {
  // 参数必须是一个数组
  if (!Array.isArray(arr)) {
    throw new Error('type err: arr 必须是一个数组')
  }
  // 存放返回结果
  const obj = {}
  arr.map((item) => {
    obj[Object.keys(item)[0]] = item[Object.keys(item)[0]]
  })
  return obj
}

// 对象数组去重
function distincts(arrs, type) {
  var arr = arrs
  const paramName = type || 'id'
  for (var i = 0; i < arr.length - 1; i++) {
    for (var j = i + 1; j < arr.length; j++) {
      // 如果元素相等了，就删除后面的那个元素
      if (arr[i][paramName] === arr[j][paramName]) {
        arr.splice(j, 1) // 删除元素
      }
    }
  }
  return arr
}
// 任务时间颜色值判断
function getDateBgColor(startDate, endDate) {
  // 1:不在当前时间 无颜色  2:在当前范围  3:范围当前时间前面
  var dd = new Date().getTime()
  if (
    (startDate === '' || startDate === null) &&
    (endDate === '' || endDate === null)
  ) {
    return 1
  }
  if (startDate === '' || startDate == null) {
    if (endDate !== '' && endDate !== null) {
      var endDateTime = new Date(endDate.replace(/\-/g, '/')).getTime()
      if (endDateTime <= dd) {
        return 3
      } else {
        return 1
      }
    } else {
      return 1
    }
  } else if (endDate === '' || endDate == null) {
    return 1
  } else {
    var startDateTime = new Date(startDate.replace(/\-/g, '/')).getTime()
    const endDateTime = new Date(endDate.replace(/\-/g, '/')).getTime()
    if (dd >= startDateTime && dd <= endDateTime) {
      return 2
    } else if (dd >= endDateTime) {
      return 3
    } else {
      return 1
    }
  }
}
// 数组元素转化成int类型
function arrToInt(arr) {
  return arr.map((item) => parseInt(item))
}
// 简单数组去重
function sole(arr) {
  return Array.from(new Set(arr))
}
// 会议判断时间
function currentDay(data) {
  var minutes = 1000 * 60
  var hours = minutes * 60
  var days = hours * 24
  var d = new Date()
  var t = d.getTime()
  var x = new Date(data).getTime()

  var y = Math.round((x - t) / days)
  return y
}
// 克隆
function clone(val) {
  return JSON.parse(JSON.stringify(val))
}
// 防抖
function debounce(fn, time = 300) {
  let timeout = null
  return function () {
    const context = this
    const args = arguments
    timeout !== null && clearTimeout(timeout)
    timeout = setTimeout(function () {
      fn.apply(context, args)
    }, time)
  }
}
// 节流
function throttle(fn, time = 300) {
  let timer = null
  return function () {
    if (!timer) {
      timer = setTimeout(function () {
        fn()
        timer = null
      }, time)
    }
  }
}
// 防止复制触发点击事件
function getSelectedClick() {
  if (window.getSelection) {
    return window.getSelection().toString()
  } else if (document.getSelection) {
    return document.getSelection().toString()
  } else {
    var selection = document.selection && document.selection.createRange()
    if (selection.text) {
      return selection.text.toString()
    }
    return ''
  }
}
// 判断微信 app pc 浏览器
function isQyweixin(type) {
  // 判断当前入口是PC端还是APP端
  const flag =
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    ) != null
  if (type === 1) {
    return flag ? 'app' : 'pc'
  } else {
    return flag ? (is_weixin() ? 'app' : '') : is_weixin() ? 'pc' : 'browser'
  }
}

function is_weixin() {
  // 判断是在企业微信打开 还是 在浏览器打开
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}

function redirectUrl(state) {
  if (is_weixin()) {
    if (window.location.host == 'qywx-test.yuanmubiao.com') {
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww95a5c55d3c33b128&redirect_uri=${windowLocationHref()}&response_type=code&scope=snsapi_privateinfo&state=sdfds343#wechat_redirect`
      window.location.href = url
    } else if (window.location.host == 'qywx-rel.yuanmubiao.com') {
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww60dc9b54f96d3116&redirect_uri=${windowLocationHref()}&response_type=code&scope=snsapi_privateinfo&state=sdfds343#wechat_redirect`
      window.location.href = url
    } else if (window.location.host == 'qw.okrt.com') {
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwbde7e21f8f8f6f65&redirect_uri=${windowLocationHref()}&response_type=code&scope=snsapi_privateinfo&state=sdfds343#wechat_redirect`
      window.location.href = url
    } else {
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww3d24e84fef56e1c3&redirect_uri=${windowLocationHref()}&response_type=code&scope=snsapi_privateinfo&state=sdfds343#wechat_redirect`
      window.location.href = url
    }
  } else if (
    window.location.host.includes('qywx') ||
    window.location.host.includes('qw')
  ) {
    // 不要打开，因为其它平台会跳转
    window.location.href = `https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect?appid=ww78733e57bfd8110f&redirect_uri=${windowLocationHref()}&state=web_login@gyoss9&usertype=member`
  }
}

function formatFileSize(bytes) {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + ' GB'
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + ' MB'
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + ' KB'
  } else if (bytes > 1) {
    bytes = bytes + ' bytes'
  } else if (bytes == 1) {
    bytes = bytes + ' byte'
  } else {
    bytes = '0 byte'
  }
  return bytes
}

function floatToFixedDown(data, state) {
  // state: 1 返回是否截取精度
  var strs = new Array()
  if ((data || '') !== '' && data !== 0 && (data + '').indexOf('.') > -1) {
    strs = (data + '').split('.')
    if (strs.length >= 2) {
      if (state === 1) {
        return strs[1].length > 2
      } else {
        return parseFloat(strs[0] + '.' + strs[1].substring(0, 2))
      }
    }
  } else {
    if (state === 1) {
      return false
    } else {
      return data.toFixed(2)
    }
  }
}

function stringByteLength(s) {
  var totalLength = 0
  var i
  var charCode
  if (s) {
    for (i = 0; i < s.length; i++) {
      charCode = s.charCodeAt(i)
      if (charCode < 0x007f) {
        totalLength = totalLength + 1
      } else if (charCode >= 0x0080 && charCode <= 0x07ff) {
        totalLength += 2
      } else if (charCode >= 0x0800 && charCode <= 0xffff) {
        totalLength += 3
      }
    }
  }
  return totalLength
}

function isWxMac() {
  // 判断是在企业微信苹果电脑打开
  return (
    navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1 &&
    /macintosh|mac os x/i.test(navigator.userAgent.toLowerCase())
  )
}

function formatNumSize(num) {
  if (!num || isNaN(num)) {
    return '请传入数值格式的数据'
  }
  // 此处为防止字符串形式的数值进来，因为toFixed方法只能用于数值型数
  num = Number(num)
  if (Math.abs(num) > 100000000) {
    return (num / 100000000).toFixed(2) + '亿'
  } else if (Math.abs(num) > 10000) {
    return (num / 10000).toFixed(2) + '万'
  } else {
    return num
  }
  return num
}
// 判断微信 app pc 浏览器
function getChromeType(type) {
  // 判断当前入口是PC端还是APP端
  const flag =
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    ) != null
  return flag ? 'app' : 'pc'
}
export default {
  isWxMac,
  getChromeType,
  formatNumSize,
  stringByteLength,
  floatToFixedDown,
  formatFileSize,
  getQueryStringHash,
  windowLocationHref,
  redirectUrl,
  ticketAgentUrl,
  getQueryString,
  changeToOkrtFormat,
  changeToHtml,
  changeToHtmlUser,
  tagFilter,
  arrToObj,
  distincts,
  getDateBgColor,
  arrToInt,
  sole,
  currentDay,
  clone,
  debounce,
  throttle,
  getSelectedClick,
  isQyweixin,
  is_weixin,
  ...timeHandle
}