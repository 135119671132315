const state = {
  globalMessageData: {},
  userInfo: '',
  token: 'eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIxODMxNzI3NjY5OCIsInVzZXJJZCI6MTI1MzQ3ODYyMjAwNzY5NzQwOCwibmFtZSI6IualmuWjq-S4vV_mpZrlo6vkuL1f5qWa5aOr5Li9X-almuWjq-S4vV_mpZrlo6vkuL1fIiwidXNlck5hbWUiOiIxODMxNzI3NjY5OCIsImNvbXBhbnlJZCI6MTE2NTk1MjE2MTAyNjEwOTQ0MCwidGVuYW50SWQiOjEsInVzZXJhdmF0YXIiOiJodHRwOi8veXVhbm11Ymlhby10ZXN0Lm9zcy1jbi1zaGFuZ2hhaS5hbGl5dW5jcy5jb20vb3NzLzIwMjEwODI1LzYxZDU2YjA4ZTRlZTQ3MWY5NTE3NWVmYTZiNDg1NTdkLnBuZz9FeHBpcmVzPTE5NDUyNDYwMzkmT1NTQWNjZXNzS2V5SWQ9TFRBSTV0RVYyVzJ0c3VYQ2tNUUJzV1BGJlNpZ25hdHVyZT0wNjZGJTJCbm9DVkt2SHZJd3RyVyUyRlBsbkVqcDc4JTNEIiwiY29tcGFueU5hbWUiOiLmupDnm67moIciLCJleHAiOjE3MDI4OTIzMTd9.c2Hd9g7uUc4-Ke2vWTrfSrF4IIrzKs2VLqN2j8z_DS6YkDmFN151n8y7mCeHvjwP-XtDvO8oGUn9kBhk5_kr8re4pBdsKu4jCMul4rT-hvwjc30BXIiv-nN4HqoZXK96VlldjX1A15bqRsbHijCkJRs2Fg3jkuCxJRMAq50j96s'
}
const actions = { // 处理异步或者复杂 调用方式：this.$store.dispatch('_isFirstBtn')
  // 当前前项目id
  _isFirstBtn(context, val) {
    context.commit('isFirstBtn', val)
  }
}
const mutations = { // 同步 调用方式：this.$store.commit('_isFirstBtn')
  isFirstBtn(state, val) {
    state.isFirstBtn = val
  },
  globalMessageData(state, val) {
    state.globalMessageData = val
  },
  setUserInfo(state, val) {
    state.userInfo = val
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations
}