<template>
  <div class="header-wrap" v-scroll-bottom="$loadMoreList">
    <div class="header-left" @click="commonData">登录左侧</div>
    <div class="header-right">
      登录右侧
      <Icon icon-class="icon-shanchuzhibiao" class="close" />
    </div>
    <el-button type="primary">Primary</el-button>
    <el-color-picker v-model="color1" />
    <div style="border: #efefef solid 1px; height: calc(100vh - 100px);width: 100%;">
      <relation-graph ref="graphRef$" :options="options" />
    </div>
  </div>
</template>
<script>
import RelationGraph from "relation-graph/vue3";
import { ElMessage } from "element-plus";
export default {
  components: {
    RelationGraph
    // Icon
  },
  props: {},
  data() {
    return {
      isFirstBtn: "",
      loading: true,
      options: {
        defaultExpandHolderPosition: "right"
      },
      jsonData: {
        rootId: "a",
        nodes: [
          { id: "a", text: "a" },
          { id: "b", text: "b" },
          { id: "c", text: "c" },
          { id: "d", text: "d" },
          { id: "e", text: "e" },
          { id: "f", text: "f" }
        ],
        lines: [
          { from: "a", to: "b" },
          { from: "a", to: "c" },
          { from: "a", to: "d" },
          { from: "a", to: "e" },
          { from: "a", to: "f" }
        ]
      },
      color1: "#409EFF"
    };
  },
  created() {
    this.$store.commit("company/isFirstBtn", 2);
    this.isFirstBtn = this.$store.state.company.isFirstBtn;
    this.commonData();
  },
  mounted() {
    setTimeout(() => {
      this.getLoading();
    }, 3000);
    this.$refs.graphRef$.setJsonData(this.jsonData);

    ElMessage({
      message: "Congrats, this is a success message.",
      type: "success"
    });
  },
  methods: {
    // 获取需要提交的数量（不包括延期的）
    commonData() {
      this.$apis
        .COMMONDATA()
        .then(res => {})
        .catch(err => {
          console.log(err);
        });
    },
    $loadMoreList() {
      console.log("loadMoreList");
    },
    getLoading() {
      this.loading = false;
    }
  }
};
</script>
<style lang="less" scoped>
.header-wrap {
  .flex-c();
  align-items: flex-start;
  height: 80vh;
  overflow-y: auto;
  width: 100%;
  .header-left {
    font-weight: bold;
    height: 100vh;
  }
  .header-right {
    .flex-l();
  }
}
</style>
