// 滚动到底部触发
export default {
  mounted(el, binding) {
    // 获取element，定义scroll
    const select_dom = el.querySelector(
      '.el-select-dropdown .el-select-dropdown__wrap'
    )
    select_dom.addEventListener('scroll', function () {
      const height = this.scrollHeight - this.scrollTop <= this.clientHeight
      if (height) {
        binding.value()
      }
    })
  }
}