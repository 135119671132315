<template>
  <transition name="left-slide" mode="out-in">
    <div
      v-if="$store.state.user.globalMessageData.isShow && $store.state.user.globalMessageData.msg!== ''"
      ref="message"
      :key="`${$store.state.user.globalMessageData.msg}${$store.state.user.globalMessageData.isShow}`"
      class="msg-wrap"
    >
      <div
        :class="[
          'msg',
          `global-msg`,
          $store.state.user.globalMessageData.type === 'success' ? 'success' : $store.state.user.globalMessageData.type === 'error' ? 'error' : 'warn'
        ]"
      >
        <i
          :class="[
            'iconfont',
            $store.state.user.globalMessageData.type === 'success' ? 'ymbsuccess' : 'ymbguanbianniu'
          ]"
        ></i>
        <div class="content">
          <span
            v-if="$store.state.user.globalMessageData.code != 200 && $store.state.user.globalMessageData.code"
          >{{ $store.state.user.globalMessageData.code }}：</span>
          {{ $store.state.user.globalMessageData.msg }}
        </div>
        <Icon icon-class="icon-shanchuzhibiao" @click="close" class="close" />
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      timer: null
    };
  },
  created() {},
  mounted() {
    // 添加计时器自动关闭
    let flag = 0;
    this.timer = setInterval(() => {
      if (flag < 2) {
        flag++;
      } else {
        this.$store.commit("user/globalMessageData", {
          isShow: false
        });
        if (this.$refs.message) {
          this.$refs.message.style.display = "none";
        }
        clearInterval(this.timer);
      }
    }, 1000);
  },
  beforeDestroy() {
    this.timer = null;
  },
  methods: {
    close() {
      this.$store.commit("user/globalMessageData", {
        isShow: false
      });
    }
  }
};
</script>
<style lang="less" scoped>
.msg-wrap {
  max-width: 100%;
}
.iconfont {
  font-size: 22px;
}
.global-msg {
  .mg-t-n(20);
  min-height: 22px;
  line-height: 22px;
  padding: 10px 0px;
}
.content {
  .min-w(192);
  .mg-l(10);
  .mg-r(14);
  .text-n(4);
  font-size: 16px;
}
.msg {
  background: #fff5f9;
  .bd-rd(8);
  .shadow(0, 4, 16, 0, rgba(17, 17, 17, 0.08));
  .pl(20);
  .pr(15);
  .flex-l();
}
.title {
  .pl(12px);
}

.close {
  cursor: pointer;
}
// 各自的背景颜色和文字颜色
.error {
  background-color: #fff5f9;
  color: #e60061;
  .bd-l(solid, 5, #e60061);
}
.success {
  background-color: #e9fbf5;
  color: #00b282;
  .bd-l(solid, 5, #00b282);
}
.warn {
  background-color: #fff9ef;
  color: #f99602;
  .bd-l(solid, 5, #f99602);
}
// 过度动画
::v-deep .slide-enter-active,
::v-deep .slide-leave-active {
  transition: opacity 0.5s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}
</style>
<style lang="less">
#global-msg-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  .p-f();
  .b(50);
  .l(30);
  width: auto;
  max-width: calc(100vw - 30px);
  z-index: 999999999;
  text-align: left;
}
/* left-slide 左滑 */
/* right-slide 右滑 */
.left-slide-leave-active,
.left-slide-enter-active,
.right-slide-leave-active,
.right-slide-enter-active {
  transition: all 0.2s;
}

.left-slide-enter,
.left-slide-leave-to {
  opacity: 0;
  transform: translate3d(-100vw, 0, 0);
}

.left-slide-enter-to,
.left-slide-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
</style>
