<template>
  <div class="app-content-wrap">
    <router-view />
    <div id="global-msg-wrap">
      <Message v-if="$store.state.user.globalMessageData.isShow" />
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  methods: {}
};
</script>
<style lang="less">
#app {
  font-family: Helvetica Neue, pingfang SC, arial, hiragino sans gb,
    microsoft yahei ui, microsoft yahei, simsun, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #444558;
}
// 页面加载进度条
#nprogress .bar {
  background: #3a78f6 !important;
}
.app-content-wrap {
  height: 100%;
}
// 全局滚动条自定义
* {
  scrollbar-width: thin; //火狐浏览器
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    // background-color: #eef1f6;
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0);
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #d0cfd4;
    }
  }
}
</style>
