import vueloading from './loading'
import scrollBottom from './scroll-bottom'
import preventReClick from './preventReClick'
import clearTabIndex from './clearTabIndex'
import selectScrollBottom from './select-scroll-bottom'
export default {
  install(app) {
    app.directive('vueloading', vueloading)
    app.directive('scrollBottom', scrollBottom)
    app.directive('preventReClick', preventReClick)
    app.directive('clearTabIndex', clearTabIndex)
    app.directive('selectScrollBottom', selectScrollBottom)
  }
}