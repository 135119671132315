import "element-plus/dist/index.css";
import {
  ElButton,
  ElColorPicker,
  ElAlert,
  ElMessage
} from 'element-plus'
export default {
  install(app) {
    app.component('ElButton', ElButton)
    app.component('ElColorPicker', ElColorPicker)
    app.component('ElAlert', ElAlert)
    app.component('ElMessage', ElMessage)
  }
}