import {
  createVNode,
  render
} from "vue";
import LoadingComponent from './loading.vue'
import Message from '@/components/global/Message' // 全局信息提示
let mountNode = null;
export default {
  mounted(el, binding) {
    //确保只存在一个弹框，如果前一个弹窗还在，就移除
    // if (mountNode) {
    //   document.body.removeChild(mountNode);
    //   mountNode = null;
    // }
    const app = createVNode(LoadingComponent);
    // 如果超时，返回空图片
    const timer = setTimeout(() => {
      if (binding && binding.instance && binding.instance.visible) {
        el.removeChild(mountNode);
        Message.error('请求超时')
      }
      clearInterval(timer)
    }, 30000)
    mountNode = document.createElement("div");
    //render函数的作用就是将组件的虚拟DOM转换成真实DOM并插入到mountNode元素里
    render(app, mountNode);
    //然后把转换成真实DOM的Notice组件插入到body里
    el.appendChild(mountNode);
    el.style.setProperty('position', 'relative', 'important')
    // if (binding.instance && binding.instance.$el) {
    //   binding.instance.$el.style.setProperty('position', 'absolute', 'important')
    // }
  },
  /**
   * 组件的VNode更新时调用
   * @param {*} el
   * @param {*} binding
   */
  updated(el, binding) {
    if (binding.value === false) {
      el.removeChild(mountNode);
    }
  },
  /**
   * 只调用一次；指令与元素解绑是调用
   * @param {*} el
   */
  unmounted(el, binding) {
    const vueloading = binding.instance.$el
    if (vueloading.parentNode) {
      vueloading.parentNode.removeChild(vueloading)
    }
    // binding.instance.$destroy()
    binding.instance = undefined
  }
}