// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./img/component-loading.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*[data-v-3c6564f7] {\n  padding: 0;\n  margin: 0;\n  outline: none !important;\n}\nbutton[data-v-3c6564f7] {\n  outline: none !important;\n}\nbutton[data-v-3c6564f7]:focus {\n  outline: 0 !important;\n}\nbutton[data-v-3c6564f7]::-moz-focus-inner {\n  border-color: transparent;\n}\n/*for mozilla*/\n.el-drawer[data-v-3c6564f7] {\n  box-shadow: none !important;\n}\n.text-overOne[data-v-3c6564f7] {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n.text-overOne[data-v-3c6564f7]:before {\n  content: '';\n  display: block;\n}\n.ymb-container[data-v-3c6564f7] {\n  width: 100%;\n  overflow-x: auto !important;\n  background: #fff !important;\n}\nhtml[data-v-3c6564f7] {\n  margin: 0;\n  padding: 0;\n  border: 0;\n  height: 100%;\n  font-size: 14px;\n  overflow: hidden !important;\n  font-family: Helvetica Neue, pingfang SC, arial, hiragino sans gb, microsoft yahei ui, microsoft yahei, simsun, sans-serif !important;\n}\n.u-loading-mask[data-v-3c6564f7] {\n  position: absolute;\n  z-index: 9999999;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  color: #ffffff;\n}\n.u-loading-mask .u-loading-spinner[data-v-3c6564f7] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 74px 74px;\n  background-position: center;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
