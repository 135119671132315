// 公用模块方法库
import apis from '../apis_moudles/index'
import req from '../request'
// 文件上传
// const FILEUPLOAD = (params) => {
//   return req.post(apis.fileUpload, params, {
//     timeout: 120000,
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     }
//   })
// }
// 公共数据查询接口
const COMMONDATA = (params) => {
  return req.get(apis.commonData, {
    params
  })
}

export default [{
  COMMONDATA
}]