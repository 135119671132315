// 根据环境变量确认baseurl
let baseUrl = ''
switch (process.env.NODE_ENV) {
  case 'dev':
    baseUrl = `https://qywx-dev.yuanmubiao.com` // 开发环境
    break
  case 'envtest':
    baseUrl = `https://qywx-test.yuanmubiao.com` // 测试环境
    break
  case 'production':
    baseUrl = `https://qw.okrt.com` // 正式环境
    break
}
export default baseUrl