// import Vue from 'vue'
// import debounce from 'lodash/debounce' // 局部引入防抖

// // 滚动到底部触发
// export default () => {
//   Vue.directive('scroll-bottom', {
//     bind(el, binding) {
//       if (el) {
//         const dataset = el.dataset
//         const type = dataset && dataset.scrollType
//         const scollBottom = function () {
//           if (this.scrollHeight - this.scrollTop - this.clientHeight < 50) {
//             binding.value({
//               type
//             })
//           }
//         }
//         el.addEventListener('scroll', debounce(scollBottom, 200))
//       }
//     }
//   })
// }
import debounce from 'lodash/debounce' // 局部引入防抖
// 滚动到底部触发
export default {
  mounted(el, binding) {
    if (el) {
      const dataset = el.dataset
      const type = dataset && dataset.scrollType
      const scollBottom = function () {
        if (this.scrollHeight - this.scrollTop - this.clientHeight < 50) {
          binding.value({
            type
          })
        }
      }
      el.addEventListener('scroll', debounce(scollBottom, 200))
    }
  }
}