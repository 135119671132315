<template>
  <div class="all-page">
    <div class="not-find-page">
      <p class="tip">未找到该页面</p>
      <el-button class="back-btn" @click="back">返回上一页</el-button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    back() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="less" scoped>
.all-page {
  background-size: cover;
  padding: 32px;
}
.icon-back {
  font-size: 24px;
  border-radius: 4px;
  color: #0077ff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  &:hover {
    background: rgba(0, 119, 255, 0.06);
  }
}
.not-find-page {
  width: 100%;
  height: 100vh;
  color: #0077ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .top-text {
    font-size: 60px;
    font-weight: 800;
    margin-bottom: 24px;
  }
  .top-img {
    width: 180px;
    margin-bottom: 24px;
  }
  .tip {
    font-size: 18px;
  }
  .back-btn {
    width: 137px;
    height: 32px;
    line-height: 32px;
    padding: 0;
    border: 1px solid #3a78f6;
    color: #3a78f6 !important;
    background: #fff;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 24px;
    &:hover {
      color: #ffffff !important;
      border-color: #3a78f6 !important;
      background: #3a78f6 !important;
    }
  }
}
</style>
