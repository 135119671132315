const state = {
  isFirstBtn: 0
}
const actions = { // 处理异步或者复杂 调用方式：this.$store.dispatch('_isFirstBtn')
  // 当前前项目id
  _isFirstBtn(context, val) {
    context.commit('isFirstBtn', val)
  }
}
const mutations = { // 同步 调用方式：this.$store.commit('_isFirstBtn')
  isFirstBtn(state, val) {
    state.isFirstBtn = val
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations
}