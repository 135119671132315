import {
  createApp
} from 'vue';
import App from './App.vue';
const app = createApp(App);
import router from './router'
import store from './store'
import funcs from './api/funcs_modules'
app.use(router) // 路由
app.use(store) // 状态管理（分模块）
// 全局组件
import useComponents from '@/components/index.js'
app.use(useComponents)
// element按需引入
import elementComponents from '@/components/element/index.js'
app.use(elementComponents)
// 全局指令
import directives from '@/directives'
app.use(directives)
app.config.globalProperties.$apis = funcs // 数据请求，统一操作
app.mount('#app');