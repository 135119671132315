import Vue from 'vue'
import guards from './router.config'
import * as VueRouter from 'vue-router'
// 登录
import login from './modules/login'
// 布局
import Layout from '../views/global/layout/index.vue'
// 视频
// import video from './modules/video'
const routes = [{
    path: '/',
    component: Layout,
    children: [{
      path: '',
      redirect: '/login' // 页面重定向
    }, ]
  },
  ...login,
  // video,
  // 404页面
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => {
      return import( /* webpackChunkName: "user" */ '../404.vue')
    }
  }
]
const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(), // createWebHashHistory
  routes
})
// 路由加载进度
guards(router)

export default router